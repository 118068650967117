import React, { useContext } from 'react';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import ReactTooltip from 'react-tooltip';

import { ConfigContext } from '../AppShell/ConfigProvider';

import classes from './GeoFlag.scss';

export const GeoFlag: React.FC = () => {
    const geoCookieValue = Cookies.get('isIrishUser');
    const isIrishUser = geoCookieValue === 'true';
    const activeFlag = isIrishUser ? 'ireland' : 'uk';
    const { setCurrency } = useContext(ConfigContext);

    const onChangeFlag = (value: string) => {
        if (geoCookieValue === value) return null;

        Cookies.remove('isIrishUser');
        Cookies.set('isIrishUser', value, { expires: 7 });

        if (value === 'true') {
            setCurrency('EUR');
        } else {
            setCurrency('GBP');
        }

        window.location.reload();
    };

    return (
        <div className={classes.flags}>
            <div
                className={classnames([classes.flag], {
                    [classes.isActive]: activeFlag === 'ireland',
                })}
                onClick={() => onChangeFlag('true')}
                data-tip="Ireland"
                data-for="ir"
            >
                <img src={require('../../svg/ireland-flag-icon.svg')} alt="Ireland flag icon" />
            </div>
            <ReactTooltip type="dark" effect="solid" id="ir" />
            <div
                className={classnames([classes.flag], {
                    [classes.isActive]: activeFlag === 'uk',
                })}
                onClick={() => onChangeFlag('false')}
                data-tip="United Kingdom"
                data-for="uk"
            >
                <img src={require('../../svg/united-kingdom-flag-icon.svg')} alt="United Kingdom flag icon" />
            </div>
            <ReactTooltip type="dark" effect="solid" id="uk" />
            {/* <div onClick={() => Cookies.remove('isIrishUser')}>delete</div> */}
        </div>
    );
};
