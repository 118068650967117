import { setContext } from 'apollo-link-context';
import { merge, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { STORAGE_KEY, UserActions } from '@wearejh/m2-pwa-user/lib';
import { ignoreElements, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { Actions as RouterActions, LOCATION_CHANGE } from '@wearejh/m2-pwa-engine/lib/router/router.register';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';
import { useTreatments } from '@splitsoftware/splitio-react';
import { NO_AUTHORIZATION } from 'src/constants/splitFlag';
import Cookies from 'js-cookie';

import { externalLink } from './epics/externalLink.epic';
import { appReducer } from './app.reducer';
import { alertEpic } from './epics/alert.epic';

export function appRegister() {
    let token = null;
    const isIrishUser = Cookies.get('isIrishUser') === 'true';

    // This code intercepts all REST API calls made via fetch and XMLHttpRequest, rewriting URLs that contain '/rest/V1/' to '/rest/{store-id}/V1/'.
    const originalFetch = window.fetch;

    window.fetch = async (...args) => {
        let url = args[0];
        const options = args[1];

        if (typeof url === 'string' && url.includes('/rest/V1/') && isIrishUser) {
            url = url.replace('/rest/V1/', '/rest/ireland/V1/');
        }

        return originalFetch(url, options);
    };

    const originalXHR = window.XMLHttpRequest;
    class InterceptedXHR extends originalXHR {
        open(method: string, url: string) {
            if (url.includes('/rest/V1/') && isIrishUser) {
                url = url.replace('/rest/V1/', '/rest/ireland/V1/');
            }
            super.open(method, url);
        }
    }

    window.XMLHttpRequest = InterceptedXHR;
    //

    const link = setContext((request, previousContext) => {
        const next = {
            ...previousContext.headers,
        };

        /**
         * This is to allow none-visible products in queries
         */
        switch (request.operationName) {
            case 'productVariant': {
                next['Allow-NonVisible'] = 'true';
                break;
            }
        }

        const treatmentsNoAuthorization = useTreatments([NO_AUTHORIZATION]);
        const { treatment: treatmentNoAuthorization } = treatmentsNoAuthorization[NO_AUTHORIZATION];

        /**
         * This is to allow authenticated gql requests
         */
        if (treatmentNoAuthorization === 'on') {
            if (token !== null && !request.variables?.noAuthorization) {
                next['Authorization'] = `Bearer ${token}`;
            }
        } else {
            if (token !== null) {
                next['Authorization'] = `Bearer ${token}`;
            }
        }

        next['store'] = isIrishUser ? 'ireland' : 'default';

        /**
         * This is what the setContext link expects
         */
        return {
            headers: next,
        };
    });
    return {
        epics: [
            alertEpic,
            externalLink,
            (actions: Observable<any>, state$: Observable<any>, deps) => {
                const initial = deps.storage.get(STORAGE_KEY);

                if (initial && initial.token) {
                    token = initial.token;
                }
                const token$ = state$.pipe(pluck('user', 'token'));
                const signIn$ = actions.pipe(
                    ofType<UserActions>('User.SignInSuccess', 'User.Persist'),
                    withLatestFrom(token$),
                    tap(([, _token]) => (token = _token)),
                );
                const signOut$ = actions.pipe(
                    ofType<UserActions>('User.SignOut', 'User.Reset'),
                    tap(() => (token = null)),
                );
                return merge(signIn$, signOut$).pipe(ignoreElements());
            },
            (actions$: Observable<any>) => {
                return actions$.pipe(
                    ofType<RouterActions>(LOCATION_CHANGE),
                    tap(() => fireGtm({ event: 'pageview' })),
                    ignoreElements(),
                );
            },
        ],
        reducers: {
            app: appReducer,
        },
        name: 'app',
        links: [link],
    };
}
