import { version } from '../package.json';

import { AppEnv } from './types/global-types';

export type ReleaseStage = 'cypress' | 'local' | 'develop' | 'staging' | 'staging2' | 'staging3' | 'master';

export const environments: { [K in ReleaseStage]: AppEnv } = {
    cypress: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: true,
        SERVICE_WORKER: false,
        RELEASE_STAGE: 'local',
        VERSION: `local(${version})`,
        DOMAIN: 'localhost:9001',
        BACKEND: 'https://staging-m2.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '43af4d736f605523d99f6c3d7abc0dc0',
        ALGOLIA_INDEX_PREFIX: 'wearejh_staging_',
        PCA_ENABLED: false,
        PCA_FORM_KEY: 'XF39-EM19-DU47-AU75',
        GTM_ID: 'GTM-MPGDRC6',
        GTM_ENABLED: false,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: false,
        SPLITIO_AUTHORIZAYION_KEY: '1683t2gdvds5trmnnpsohg27ocio1r8gh1g5',
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
        IP_GEOLOCATION_API_KEY: 'b80f46fd95874b9e93e45fd01d476525',
    },
    local: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: true,
        SERVICE_WORKER: false,
        RELEASE_STAGE: 'local',
        VERSION: `local(${version})`,
        DOMAIN: 'localhost:9001',
        BACKEND: 'https://wwe.m2',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '52c65dc4ca61cafb6943d7bbae18995d',
        ALGOLIA_INDEX_PREFIX: 'wearejh_development_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'XF39-EM19-DU47-AU75',
        GTM_ID: 'GTM-M5MFRNT',
        GTM_ENABLED: false,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
    },
    develop: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: true,
        SERVICE_WORKER: true,
        RELEASE_STAGE: 'develop',
        VERSION: version,
        DOMAIN: 'dev-pwa.workwearexpress.com',
        BACKEND: 'https://dev-pwa.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '52c65dc4ca61cafb6943d7bbae18995d',
        ALGOLIA_INDEX_PREFIX: 'wearejh_development_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'XF39-EM19-DU47-AU75',
        GTM_ID: 'GTM-M5MFRNT',
        GTM_ENABLED: true,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
    },
    staging: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: true,
        SERVICE_WORKER: true,
        RELEASE_STAGE: 'staging',
        VERSION: version,
        DOMAIN: 'staging-pwa.workwearexpress.com',
        BACKEND: 'https://staging-m2.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '43af4d736f605523d99f6c3d7abc0dc0',
        ALGOLIA_INDEX_PREFIX: 'wearejh_staging_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'AZ93-UG91-RE29-DJ49',
        GTM_ID: 'GTM-MPGDRC6',
        GTM_ENABLED: true,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
        IP_GEOLOCATION_API_KEY: 'b80f46fd95874b9e93e45fd01d476525',
    },
    staging2: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: true,
        SERVICE_WORKER: true,
        RELEASE_STAGE: 'staging2' as any,
        VERSION: version,
        DOMAIN: 'staging2-pwa.workwearexpress.com',
        BACKEND: 'https://staging2-m2.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '43af4d736f605523d99f6c3d7abc0dc0',
        ALGOLIA_INDEX_PREFIX: 'wearejh_staging_2_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'AZ93-UG91-RE29-DJ49',
        GTM_ID: 'GTM-58HQCTW',
        GTM_ENABLED: true,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
        IP_GEOLOCATION_API_KEY: 'b80f46fd95874b9e93e45fd01d476525',
    },
    staging3: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: false,
        SERVICE_WORKER: true,
        RELEASE_STAGE: 'staging3' as any,
        VERSION: version,
        DOMAIN: 'staging3-pwa.workwearexpress.com',
        BACKEND: 'https://staging3-m2.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '369547db2f6e61c05a9fb07ec7524565',
        ALGOLIA_INDEX_PREFIX: 'wearejh_production_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'ZP99-RK61-ZU29-PY29',
        GTM_ID: 'GTM-5GVPRK2',
        GTM_ENABLED: true,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        SMARTLOOK_ID: '0c0c279e5220c9afc1da2cde73569c03b5e9da31',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
        IP_GEOLOCATION_API_KEY: 'b80f46fd95874b9e93e45fd01d476525',
    },
    master: {
        NODE_ENV: process.env.NODE_ENV as any,
        REDUX_STORE_TOOLS: false,
        SERVICE_WORKER: true,
        RELEASE_STAGE: 'production',
        VERSION: version,
        DOMAIN: 'www.workwearexpress.com',
        BACKEND: 'https://www.workwearexpress.com',
        ALGOLIA_APP_ID: 'M687QMBEFI',
        ALGOLIA_API_KEY: '369547db2f6e61c05a9fb07ec7524565',
        ALGOLIA_INDEX_PREFIX: 'wearejh_production_',
        PCA_ENABLED: true,
        PCA_FORM_KEY: 'ZP99-RK61-ZU29-PY29',
        GTM_ID: 'GTM-5GVPRK2',
        GTM_ENABLED: true,
        LOGROCKET_ID: '9s8hf1/magento-site',
        SSR_GQL: true,
        SPLITIO_AUTHORIZAYION_KEY: '719s9or2pupmpd49rgee1cfbg3hm35kfjj6c',
        BUGSNAG_ID: 'c224a0a74d076a4464d374226b96d051',
        SMARTLOOK_ID: '0c0c279e5220c9afc1da2cde73569c03b5e9da31',
        RECAPTCHA_ID: '6LdkxBAqAAAAAMihev_ZvcpsCwtETrJBsy1dnqzr',
        IP_GEOLOCATION_API_KEY: 'b80f46fd95874b9e93e45fd01d476525',
    },
};

export function env(name: ReleaseStage): AppEnv {
    if (environments[name]) {
        return environments[name];
    }
    console.error('RELEASE_STAGE not recognised');
    return environments['master'];
}

export default environments;
