import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrencyCodes } from '@wearejh/m2-pwa-currency/lib/hooks/useCurrencyCodes';
import { currency } from 'src/queries/__generated__/currency';
import { useDeps } from 'src/hooks/useDeps';
import { ProductPrice } from 'src/types/global-types';
import Bugsnag from '@bugsnag/js';
import { toNumber } from 'lodash';
import Cookies from 'js-cookie';

import { environments } from '../../env';
import currenciesQuery from '../../queries/currencies.graphql';

const noop = (name: string) => {
    return () => {
        // eslint-disable-next-line no-console
        console.log(`${name} is missing - did you include the register function for it?`);
        return undefined;
    };
};

export type Currency = { code: string; symbol: string };
export type SetupCharge = ProductPrice;
export type Reviews = {
    average_rating: number;
    rated_as: string;
    total_reviews: number;
};

type ConfigContextType = {
    setCurrency: (code: string) => undefined;
    availableCurrencies: Currency[];
    currentCurrency: Currency;
    setupCharge: SetupCharge;
    furniture: {
        headerPhoneNumber: string | null;
    };
    liveChat: {
        enabled: boolean;
        apiKey: string | null;
    };
    gtm: {
        enabled: boolean;
        id: string | null;
    };
    cutOffTime?: string[];
    blackoutDays?: Record<
        string,
        {
            blackout_date: string;
        }
    >;
    additionalDays: number;
};

const isIrishUser = Cookies.get('isIrishUser') === 'true';
const DEFAULT_CURRENCY = isIrishUser ? 'EUR' : 'GBP';
const DEFAULT_SYMBOL = isIrishUser ? '€' : '£';

const initialState: ConfigContextType = {
    setCurrency: noop('setCurrency'),
    availableCurrencies: [
        { code: 'GBP', symbol: '£' },
        { code: 'EUR', symbol: '$' },
    ],
    currentCurrency: { code: DEFAULT_CURRENCY, symbol: DEFAULT_SYMBOL },
    furniture: {
        headerPhoneNumber: null,
    },
    liveChat: {
        enabled: false,
        apiKey: null,
    },
    gtm: {
        enabled: false,
        id: null,
    },
    setupCharge: {
        regular_price_incl: 0,
        regular_price: 0,
        final_price_incl: 0,
        final_price: 0,
        price: 0,
        price_incl: 0,
    },
    cutOffTime: [],
    blackoutDays: undefined,
    additionalDays: 0,
};

const isProd = process.env.NODE_ENV === 'production';
export const ipGeolocationApiKey: string | any = isProd
    ? environments['master'].IP_GEOLOCATION_API_KEY
    : environments['cypress'].IP_GEOLOCATION_API_KEY;

export const setCookie = (key: string, value: string) => {
    Cookies.set(key, value, { expires: 7 });
};

export const setInitialGeoCookie = (ipGeolocationApiKey, setCookie) => {
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${ipGeolocationApiKey}`, {
        method: 'GET',
        redirect: 'follow',
    })
        .then((response) => response.json())
        .then((result) => {
            if (result?.country_name === 'Ireland') {
                setCookie('isIrishUser', 'true');
            } else {
                setCookie('isIrishUser', 'false');
            }
        })
        .catch((error) => console.error(error));
};

export const ConfigContext = React.createContext<ConfigContextType>(initialState);

const EMPTY_ARR = [];
export const ConfigProvider: React.FC = React.memo((props) => {
    const { env } = useDeps();
    const { setCurrency, code } = useCurrencyCodes<currency>(currenciesQuery, env.SSR_GQL);
    const [data, setData] = useState<currency>();

    const getCookie = (key: string) => {
        return Cookies.get(key);
    };

    const isIrishUser = getCookie('isIrishUser') === 'true';

    useEffect(() => {
        fetch(`${location.origin}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Store: isIrishUser ? 'ireland' : 'default',
            },
            body: JSON.stringify({ query: currenciesQuery.loc.source.body }),
        })
            .then((res) => res.json())
            .then((data) => setData(data.data));
    }, [isIrishUser]);

    useEffect(() => {
        if (!getCookie('isIrishUser')) {
            setInitialGeoCookie(ipGeolocationApiKey, setCookie);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const availableCurrencies = useMemo(() => {
        return ((data && data.currencySymbols) || EMPTY_ARR) as Currency[];
    }, [data]);

    const currentCurrency: Currency = useMemo(() => {
        if (!code && availableCurrencies.length > 0) {
            return availableCurrencies[0] as Currency;
        }
        const match = availableCurrencies.find((a) => a.code === code);
        if (match) {
            return match as Currency;
        }
        return {
            code: code!,
            symbol: DEFAULT_SYMBOL,
        };
    }, [availableCurrencies, code]);

    const furniture = {
        headerPhoneNumber: '0800 028 5867',
    };

    /**
     * Setup charges for customisations
     */
    const setupCharge: SetupCharge = useMemo(() => {
        if (!data) return initialState.setupCharge;
        const charge = data?.storeConfig?.highest_setup_charge;
        if (!charge) return initialState.setupCharge;
        return {
            regular_price_incl: 0,
            regular_price: 0,
            final_price_incl: 0,
            final_price: 0,
            price: charge.excl_tax ?? 0,
            price_incl: charge.incl_tax ?? 0,
        };
    }, [data]);

    const isProd = process.env.NODE_ENV === 'production';
    const liveChatEnabled = data?.storeConfig?.livechat_general_enabled === '1';

    const liveChat = {
        enabled: isProd && liveChatEnabled,
        apiKey: data?.storeConfig?.livechat_general_license || '',
    };

    const cutOffTime = data?.storeConfig?.cutoff_time;

    const gtm = {
        enabled: env.GTM_ENABLED,
        id: env.GTM_ID,
    };

    const blackoutDays = data?.storeConfig?.blackout_days ? JSON.parse(data?.storeConfig?.blackout_days) : undefined;

    const additionalDays = toNumber(data?.storeConfig?.extra_days_new_artwork);

    return (
        <ConfigContext.Provider
            value={{
                setCurrency,
                availableCurrencies,
                currentCurrency,
                furniture,
                liveChat,
                gtm,
                setupCharge,
                cutOffTime,
                blackoutDays,
                additionalDays,
            }}
        >
            {props.children}
        </ConfigContext.Provider>
    );
});

export function useCurrencyChange(fn: (...args: []) => Promise<any>) {
    const { currentCurrency } = useContext(ConfigContext);
    const track = useRef(currentCurrency.code);
    const { code } = currentCurrency;
    useEffect(() => {
        if (code !== track.current) {
            fn().catch((e) => {
                Bugsnag.notify('Error from refetch', (event) => {
                    event.addMetadata('errorObject', e);
                });
            });
            track.current = code;
        }
    }, [track, code, fn]);
}
