import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useSelector } from 'react-redux';
import { sendFbConversionEvent } from 'src/features/analytics/utils/sendFbConversionEvent';
import { v4 as uuidv4 } from 'uuid';
import { useTreatments } from '@splitsoftware/splitio-react';
import Bugsnag from '@bugsnag/js';
import { KEYWORDS } from 'src/constants/meta';

import { StoreState } from '../../types/global-types';
import { currentUrl } from '../../util/currentUrl';
import { useDeps } from '../../hooks/useDeps';

type HeadProps = Record<string, unknown>;

const selector = (s: StoreState) => s.router.location.pathname;

export const Head: React.FC<HeadProps> = () => {
    const pathname = useSelector(selector, shallowEqual);
    const { DOMAIN } = useDeps().env;
    const url = currentUrl(DOMAIN!, pathname);
    const eventId = uuidv4();
    const featureName = 'Banner_Cookie';
    const treatments = useTreatments([featureName]);

    const { treatment } = treatments[featureName];

    sendFbConversionEvent({
        data: [
            {
                event_name: 'PageView',
                event_time: Math.floor(Date.now() / 1000),
                action_source: 'website',
                event_source_url: url,
                event_id: eventId,
                user_data: {
                    external_id: [eventId],
                },
            },
        ],
    }).catch((error) => {
        Bugsnag.notify(error);
    });

    return (
        <Helmet titleTemplate="%s | Workwear Express">
            <link rel="canonical" href={url} />
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
            <link rel="apple-touch-icon" sizes="180x180" href="/static/icons/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/static/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/static/icons/favicon-16x16.png" />
            <link rel="manifest" href="/static/icons/site.webmanifest" />
            <link rel="mask-icon" href="/static/icons/safari-pinned-tab.svg" color="#476ce1" />
            <link rel="shortcut icon" href="/static/icons/favicon.ico" />
            <link rel="preload" href={require('../../scss/fonts/3AF6AE_0_0.woff2')} as="font" crossOrigin="true" />
            <link rel="preload" href={require('../../scss/fonts/3AF6AE_1_0.woff2')} as="font" crossOrigin="true" />
            <link rel="preload" href={require('../../scss/fonts/3AF6AE_2_0.woff2')} as="font" crossOrigin="true" />
            <meta name="msapplication-TileColor" content="#00aba9" />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <meta name="msapplication-config" content="/static/icons/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="keywords" content={KEYWORDS} />
            <meta name="facebook-domain-verification" content="wuqeo4e0c59naz0ozd5rs860w76kdt" />
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="Workwear Express" />
            <meta property="og:image" content="/static/icons/og-logo.jpg" />
            <meta name="google-site-verification" content="IzC2bmGPjDGUEjkQBm79hW3urlfBUzlGp_Hz7WcJnj4" />
            {/* Facebook Pixel: */}
            <script type="text/plain" className="optanon-category-C0004">
                {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '533751407052024');
                fbq('track', 'PageView', null ,{eventID:` +
                    `'${eventId}'` +
                    `});
                fbq.disablePushState = true
                fbq.allowDuplicatePageViews = true
                
            `}
            </script>
            {/* OneTrust: */}
            {treatment === 'on' && (
                <script
                    type="text/javascript"
                    src="https://cdn-ukwest.onetrust.com/consent/4aebcfe1-bb2f-4083-a8ba-6352c58b73e1/OtAutoBlock.js"
                />
            )}
            {treatment === 'on' && (
                <script
                    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
                    data-document-language="true"
                    type="text/javascript"
                    charSet="UTF-8"
                    data-domain-script="4aebcfe1-bb2f-4083-a8ba-6352c58b73e1"
                />
            )}
            {treatment === 'on' && <script type="text/javascript">function OptanonWrapper() {}</script>}
            <script
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                async
            ></script>
        </Helmet>
    );
};

interface Meta {
    data: {
        title: string;
        description: string;
    };
    withoutPrefix?: boolean;
}

export const DefaultMeta: React.FC<PropsWithChildren<Meta>> = ({ data, withoutPrefix }) => (
    <Helmet titleTemplate={withoutPrefix ? '%s' : '%s | Workwear Express'}>
        <title>{data.title}</title>
        <meta name="title" content={data.title} />
        <meta name="description" content={data.description} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
    </Helmet>
);
