import React from 'react';
import classnames from 'classnames';
import { getBackgroundColorClassName } from 'src/components/Header/utils/Header';
import wrapperStyles from 'src/components/Layout/Wrapper.scss';
import { NavBackgroundColor } from 'src/types/global-types';

import { CurrencySelect } from '../StoreControls/CurrencySelect';
import { VatSelect } from '../StoreControls/VatSelect';
import { GeoFlag } from '../StoreControls/GeoFlag';

import classes from './HeaderStore.scss';

type Props = NavBackgroundColor;

export const HeaderStore: React.FC<Props> = ({ bg }) => {
    return (
        <div className={classes.headerStore}>
            <ul className={classes.headerStoreList}>
                <li
                    className={classnames([classes.headerStoreItem].join(' '), {
                        ...getBackgroundColorClassName(wrapperStyles, bg),
                    })}
                >
                    <CurrencySelect />
                </li>
                <li
                    className={classnames([classes.headerStoreItem].join(' '), {
                        ...getBackgroundColorClassName(wrapperStyles, bg),
                    })}
                >
                    <VatSelect />
                </li>
                <li
                    className={classnames([classes.headerStoreItem].join(' '), {
                        ...getBackgroundColorClassName(wrapperStyles, bg),
                    })}
                >
                    <GeoFlag />
                </li>
            </ul>
        </div>
    );
};
